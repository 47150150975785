<template>
  <div class="vue-map-container">
    <GmapMap
      class="vue-map"
      :center="mapInfo.center"
      :zoom="mapInfo.level"
      :map-type-id="mapInfo.mapTypeId"
      ref="mapRef"
      @click="setClickEventListener"
      :options="{
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: true,
        disableDefaultUi: false
      }"
    >
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"
        :draggable="false"
        @click="clickMarker(m)"
      />
    </GmapMap>
    <div class="info-box" v-if="isInfoData">
      <h3>INFO</h3>
      <div class="left-info">
        <div class="input-box">
          <p>사용자 아이디</p>
          <input type="text" placeholder="ID를 입력해주세요" v-model="userInfo.id" disabled>
        </div>
        <div class="input-box">
          <p>사용자 명</p>
          <input type="text" placeholder="이름을 입력해주세요" v-model="userInfo.name" disabled>
        </div>
        <div class="input-box">
          <p>드론 아이디</p>
          <input type="text" placeholder="드론 ID를 입력해주세요" v-model="droneLogInfo.deviceId" disabled>
        </div>
        <div class="input-box">
          <p>기록일시 </p>
          <input type="text" v-model="droneLogInfo.date" disabled>
        </div>
        <div class="input-box">
          <p>위도 </p>
          <input type="text" v-model="droneLogInfo.lat" disabled>
        </div>
        <div class="input-box">
          <p>경도 </p>
          <input type="text" v-model="droneLogInfo.lng" disabled>
        </div>
        <div class="input-box">
          <p>고도 </p>
          <input type="text" v-model="droneLogInfo.velocity" disabled>
        </div>
        <div class="input-box">
          <p>방향 </p>
          <input type="text" v-model="droneLogInfo.direction" disabled>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGoogleMapsAPI } from 'gmap-vue';
import axios from "@/axios";
import { getDateTimeSec } from "@/lib/prettyDate";

const koreaCenter = { lat: 36.78843106414162, lng: 127.81468678125003 };

export default {
  name: "Map",
  props:{
    fromHome:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      token: this.$store.state.token.access_token,
      mapInfo: {
        center: koreaCenter,
        level: 9, // 지도의 레벨(확대, 축소 정도),
        mapTypeId: "terrain",
      },
      markers: [],
      data: { rows: [] },
      isInfoData: false,
      droneLogInfo: {},
      userInfo: {},
    };
  },
  computed: {
    google: getGoogleMapsAPI,
  },
  mounted() {
    this.init();    
    console.log('fromHome prop:', this.fromHome); // 콘솔에서 확인
  },
  methods: {
    init() {
      this.getRecentDrones();
    },
    
    getRecentDrones() {
      const self = this;
      self.data.rows.splice(0);
      let _data = {
        date: 'recent',
      };
   
      const config = {
        method: 'get',
        url: '/locations',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${self.token}`,
        },
        params: _data,
      };

      axios(config)
        .then((querySnapshot) => {
          if (querySnapshot.data.length === 0) {
            return;
          }
          querySnapshot.data.forEach((doc) => {
            doc['date'] = getDateTimeSec(new Date(doc.createdAt));
            self.data.rows.push(doc);
            self.makeMarker(doc);
          });
        });
    },
    getUserInfo() {
      const self = this;

      const config = {
        method: 'get',
        url: `/users/${self.droneLogInfo.drone.userId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${self.token}`,
        },
      };

      axios(config).then((res) => {
        self.userInfo = { ...res.data };
      });
    },
    makeMarker(doc) {
      const self = this;
      const moveLatLon = { lat: doc.lat, lng: doc.lng };
      const position = {
        position: moveLatLon,
        info: { ...doc },
      };
      self.markers.push(position);
    },
    clickMarker(event) {
      const self = this;
      self.mapInfo.center = event.position;
      self.droneLogInfo = event.info;
      self.isInfoData = true;

      self.getUserInfo();
    },
    
    async setClickEventListener(event) {
      const self = this;
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      // 클릭 이벤트가 처리되어야 할 경우와 아닐 경우를 구분
      if (this.fromHome) {
        const result = await this.$swal({
          title: '날씨위치로 지정하시겠습니까?',
          text: `위도: ${lat}, 경도: ${lng}`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '예',
          cancelButtonText: '아니오',
        });

        if (result.isConfirmed) {
          self.$store.commit('setLocation', { lat, lng });
          
          self.$swal('설정 완료', '현재 위치가 변경되었습니다.', 'success');
        } else {
          self.$swal('취소됨', '현재 위치 변경이 취소되었습니다.', 'info');
        }
        this.$router.push({ name: 'home' });
      }
    }
  },
};
</script>

<style scoped>
.vue-map-container {
  position: relative;
}

.vue-map-container .vue-map {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
}

.info-box {
  width: 350px;
  min-height: 350px;
  position: absolute;
  background-color: white;
  bottom: 50px;
  left: 50px;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
}

.info-box h3 {
  font-weight: 500;
}

.left-info {
  width: 100%;
}

.input-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  text-align: left;
}

.input-box input {
  width: 70%;
}

.left-info .input-box p {
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  margin: 0;
  display: block;
}
</style>
